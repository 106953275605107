<template>
    <div class="d-flex align-self-stretch flex-column flex-800-row category">
        <div class="align-self-stretch category__image">
            <NuxtImg
                v-if="image?.src"
                sizes="xs:640px sm:690px xxl:800px"
                densities="1x 2x"
                :src="image?.src"
                :alt="image?.alt"
                format="webp"
                preload
            />
        </div>
        <div class="align-self-center category__content">
            <PageBreadcrumbs
                :items="breadcrumbs"
                class="category__breadcrumbs"
            />
            <h1
                class="scale-6 scale-6--bold-uppercase category__name"
                :id="slugifyString(content?.title)"
            >
                {{ content?.title }}
            </h1>
            <div
                class="scale-2 scale-2--lead category__text intro-text"
                ref="textContainer"
                v-html="content?.intro"
            ></div>
            <div
                class="scale-2 scale-2--lead category__text intro-text"
                v-html="content?.text"
                v-show="show"
            ></div>
            <div class="category__show-more">
                <NuxtLink
                    v-if="!show && content?.text"
                    class="equal-base equal-base--tighter-bold-uppercase link link--grey"
                    @click="show = true"
                >
                    {{ t('product.category.showMore') }}
                </NuxtLink>
                <NuxtLink
                    v-else-if="show"
                    class="equal-base equal-base--tighter-bold-uppercase link link--grey"
                    @click="show = false"
                >
                    {{ t('product.category.showLess') }}
                </NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { slugifyString } from '@/utils/slugify';

const { t } = useI18n();

const props = defineProps({
    breadcrumbs: {
        type: Array,
        default: () => [],
    },
    image: {
        type: Object,
    },
    content: {
        type: Object,
    },
});

const show = ref(false);
const showMore = ref(false);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.category {
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(640) {
        border: unset;
        border-radius: unset;
        overflow: unset;
    }

    &__image {
        position: relative;
        display: flex;
        width: 50%;
        height: 60vh;
        min-height: 400px;
        max-height: clamp(400px, 27.78vw + 311.11px, 800px);

        @include media-breakpoint-down(800) {
            width: unset;
        }

        @include media-breakpoint-down(640) {
            margin: 0 $padding-container-negative;
        }

        img {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            object-fit: cover;
        }
    }

    &__content {
        padding: 0 60px;
        width: 50%;

        @include media-breakpoint-down(800) {
            width: 100%;
            padding: 0;
            margin-top: 25px;
        }
    }

    &__breadcrumbs {
        margin-bottom: 40px;
    }

    &__name {
        margin-bottom: 10px;
    }

    &__show-more {
        margin-top: 20px;
    }
}
</style>
