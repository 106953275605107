<template>
    <div
        class="row"
        :class="{
            'spacer-navigation': page?.meta_layout === 'CATEGORY',
            'spacer-navigation-overlap': page?.meta_layout === 'TYPE',
            'spacer-footer': page?.body.length === 0,
        }"
    >
        <div class="col-12 spacer-140-100">
            <ProductCategory
                v-if="page?.meta_layout === 'CATEGORY'"
                :breadcrumbs="page?.breadcrumbs"
                :image="page?.data?.image"
                :content="page?.data?.content"
            />
            <ProductType
                v-if="page?.meta_layout === 'TYPE'"
                :breadcrumbs="page?.breadcrumbs"
                :image="page?.data?.image"
                :content="page?.data?.content"
            />
        </div>
        <div v-if="page?.subcategories?.length > 1" class="col-12 spacer-80-60">
            <Subcategory
                :title="t('product.category.subcategories')"
                :items="page?.subcategories"
            />
        </div>
    </div>
    <div v-if="page?.body.length > 0" class="spacer-footer">
        <CmsBody :page="page" />
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useHrefLang } from '~/composables/seo/hreflang';
import { cleanMetaString } from '@/utils/cleanMeta';

const { t } = useI18n();

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

if (!page?.value?.meta?.seo_title) {
    useHead({
        title: cleanMetaString(
            t('product.category.title', { title: page?.value?.title }),
        ),
        meta: [
            {
                property: 'og:title',
                content: cleanMetaString(
                    t('product.category.title', {
                        title: `${page?.value?.title}`,
                    }),
                ),
            },
        ],
    });
}

if (!page?.value?.meta?.search_description) {
    const descTrans =
        page?.value?.meta_layout === 'CATEGORY'
            ? 'product.category.meta'
            : 'product.category.metaType';
    useHead({
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: cleanMetaString(
                    t(descTrans, {
                        title: `${page?.value?.title}`,
                    }),
                ),
            },
            {
                property: 'og:description',
                content: cleanMetaString(
                    t(descTrans, {
                        title: `${page?.value?.title}`,
                    }),
                ),
            },
        ],
    });
}
useHrefLang(page?.value?.meta?.hreflang);
</script>

<style lang="scss" scoped></style>
