<template>
    <div class="type__image">
        <NuxtImg
            v-if="image?.src"
            sizes="xs:640px sm:690px md:910px lg:1120px xl:1375px xxl:1600px"
            densities="1x 2x"
            :src="image?.src"
            :alt="image?.alt"
            format="webp"
            preload
        />
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-800-8 type__content">
            <PageBreadcrumbs :items="breadcrumbs" class="type__breadcrumbs" />
            <h1
                class="scale-6 scale-6--bold-uppercase type__name"
                :id="slugifyString(content?.title)"
            >
                {{ content?.title }}
            </h1>
            <div
                class="scale-2 scale-2--lead type__text intro-text"
                ref="textContainer"
                v-html="content?.intro"
            ></div>
            <div
                class="scale-2 scale-2--lead type__text intro-text"
                v-html="content?.text"
                v-show="show"
            ></div>
            <div class="type__show-more">
                <NuxtLink
                    v-if="!show && content?.text"
                    class="equal-base equal-base--tighter-bold-uppercase link link--grey"
                    @click="show = true"
                >
                    {{ t('product.category.showMore') }}
                </NuxtLink>
                <NuxtLink
                    v-else-if="show"
                    class="equal-base equal-base--tighter-bold-uppercase link link--grey"
                    @click="show = false"
                >
                    {{ t('product.category.showLess') }}
                </NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { slugifyString } from '@/utils/slugify';

const { t } = useI18n();

const props = defineProps({
    breadcrumbs: {
        type: Object,
    },
    image: {
        type: Object,
    },
    content: {
        type: Object,
    },
});

const show = ref(false);
const showMore = ref(false);
const textContainer = ref(null);

onMounted(() => {
    try {
        setTimeout(() => {
            let lineHeight = parseFloat(
                getComputedStyle(textContainer.value).lineHeight,
            );
            let numberOfLines = textContainer.value.scrollHeight / lineHeight;
            if (numberOfLines > 5) {
                showMore.value = true;
            }
        }, 10);
    } catch {}
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.type {
    &__image {
        position: relative;
        display: flex;
        height: 60vh;
        min-height: 400px;
        max-height: clamp(400px, 27.78vw + 311.11px, 800px);
        overflow: hidden;

        @include media-breakpoint-down(640) {
            margin: 0 $padding-container-negative;
        }

        img {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            object-fit: cover;
        }
    }

    &__content {
        margin-top: 25px;
    }

    &__breadcrumbs {
        margin-bottom: 40px;
    }

    &__name {
        margin-bottom: 10px;
    }

    &__show-more {
        margin-top: 20px;
    }
}
</style>
